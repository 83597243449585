var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"monarch-autocomplete autocomplete autorootdiv",class:{
    'home-search': _vm.homeSearch,
    open: _vm.open,
  }},[_c('div',{staticClass:"input-group",class:{
      'input-group-sm': !_vm.homeSearch,
    }},[_c('div',{staticClass:"input-group-prepend"},[_c('button',{staticClass:"btn btn-secondary dropdown-toggle",attrs:{"type":"button"},on:{"click":_vm.toggleFilterBox}},[_vm._v(" "+_vm._s(_vm.selectDisplay)+" ")]),(_vm.catDropDown)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeFilterBox),expression:"closeFilterBox"}],staticClass:"dropdown-menu list-group dropCatList px-4"},[_c('div',{staticClass:"form-group"},[_c('b-form-radio-group',{attrs:{"options":_vm.options,"stacked":""},model:{value:(_vm.category),callback:function ($$v) {_vm.category=$$v},expression:"category"}})],1)]):_vm._e()]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.value),expression:"value"},{name:"click-outside",rawName:"v-click-outside",value:(_vm.toggleSuggestions),expression:"toggleSuggestions"}],staticClass:"form-control xform-control-sm test-input-search-text",class:{
        loading: _vm.loading,
      },attrs:{"placeholder":_vm.dynamicPlaceholder,"type":"text"},domProps:{"value":(_vm.value)},on:{"input":[function($event){if($event.target.composing)return;_vm.value=$event.target.value},_vm.debounceInput],"keydown":[_vm.inputChanged,function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.enter.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"]))return null;return _vm.down.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"]))return null;return _vm.up.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.clearSearch.apply(null, arguments)}],"focus":_vm.toggleSuggestions}}),(_vm.showSearchButton)?_c('div',{staticClass:"input-group-append"},[_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.topright",modifiers:{"topright":true}}],staticClass:"btn xbtn-sm btn-light py-0 test-button-show-all",attrs:{"type":"button","title":"Show all matching results"},on:{"click":_vm.showMore}},[_c('i',{staticClass:"p-0 m-0 fa xfa-2x fa-search-plus fa-solid"})])]):_vm._e(),(_vm.open)?_c('div',{staticClass:"dropdown-menu list-group dropList mx-2 test-input-dropdown",class:{
        'full-width-search': _vm.fullWidthSearch,
      }},[_vm._l((_vm.suggestions),function(suggestion,index){return _c('div',{key:index,staticClass:"border-bottom m-0 px-1 test-input-dropdown-option",class:{ active: _vm.isActive(index) },on:{"mousedown":function($event){return _vm.suggestionClick(index)},"mouseover":function($event){return _vm.mouseOver(index)}}},[_c('div',{staticClass:"row m-0 p-0"},[(suggestion.has_hl)?_c('div',{staticClass:"col-5"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$sanitizeText(suggestion.highlight))}})]):_c('div',{staticClass:"col-5"},[_c('strong',[_vm._v(_vm._s(suggestion.match))])]),_c('div',{staticClass:"col-4"},[_c('i',[_vm._v(_vm._s(suggestion.taxon))])]),_c('div',{staticClass:"col-3 text-align-right"},[_c('small',[_vm._v(_vm._s(suggestion.category))])])])])}),_c('div',{staticClass:"row mx-3"},[(_vm.suggestions.length === 0)?_c('div',{staticClass:"col border"},[_c('b',[_vm._v("No results for '"+_vm._s(_vm.value)+"'")])]):_vm._e()])],2):_vm._e()]),(_vm.homeSearch)?_c('div',{staticClass:"examples"},[_vm._v(" e.g. "),_vm._l((_vm.exampleSearches),function(example,index){return _c('button',{key:index,staticClass:"btn btn-outline-light m-1 py-0 example-button",attrs:{"role":"button"},on:{"click":function($event){return _vm.useExample(example.searchString, example.category)}}},[_vm._v(" "+_vm._s(example.searchString)+" ")])})],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }