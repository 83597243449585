<template><div class="vue-markdown"><html><head></head><body><div class="intro">
<p><img src="../../assets/img/monarch-logo-white-stacked.png" alt=""></p>
<p><embedded-autocomplete :home-search="true" :show-search-button="true" :auto-focus="true"></embedded-autocomplete></p>
</div>


</body></html></div></template>
<style lang="scss">
  @import "~@/style/variables";

  div.intro {
    margin: auto;
    padding: 20px;
    text-align: center;
    color: white;
    background-color: $monarch-bg-color;
    height: 275px;

    vertical-align: middle;

    img {
      max-height: 80px;
    }

    h4 {
      font-size: 20px;
    }

    @media (min-width: $grid-float-breakpoint) {
      h4 {
        font-size: 24px;
      }
    }
  }
</style>
<script>
  import MonarchAutocomplete from "@/components/MonarchAutocomplete.vue";

  export default {
    name: "Home",
    components: {
      "embedded-autocomplete": MonarchAutocomplete
    }
  };
</script>