<template><div class="vue-markdown"><html><head></head><body><div class="container-fluid monarch-view">
  <h2 class="page-title"> Citing The Monarch Initiative</h2>
<p>Please acknowledge the use of Monarch with the following paragraph:</p>
<blockquote class="blockquote"><p>{YOUR resource} uses and displays data and algorithms from the Monarch Initiative. The Monarch Initiative (<a href="https://monarchinitiative.org" target="_blank" rel="noopener noreferrer">https://monarchinitiative.org</a>) makes biomedical knowledge exploration more efficient and effective by providing tools for genotype-phenotype analysis, genomic diagnostics, and precision medicine across broad areas of disease.</p>
</blockquote>
<p>To cite the use of Monarch in publications, please use these two references:</p>
<ul>
<li>Navigating the Phenotype Frontier: The Monarch Initiative. McMurry et al. GENETICS August 1, 2016 vol. 203 no. 4 1491-1495; DOI: 10.1534/genetics.116.188870</li>
<li>The Monarch Initiative in 2019: an integrative data and analytic platform connecting phenotypes to genotypes across species. Shefchek et al. Nucleic Acids Res. 2020 Jan 8; 48(Database issue): D704–D715. Published online 2019 Nov 08. doi: 10.1093/nar/gkz997</li>
</ul>
<p>To cite a specific data page or result in Monarch:</p>
<blockquote class="blockquote"><p>Because most of the association content in Monarch comes from external sources, we kindly ask that you please cite the original database records (e.g. <a href="http://www.informatics.jax.org/accession/MGI:1915196" target="_blank" rel="noopener noreferrer">http://www.informatics.jax.org/accession/MGI:1915196</a>) or the original publications (e.g. PMID:25605924), as appropriate.</p>
</blockquote>
<p>If you wish to link from your site to a specific data page from the Monarch Initiative website, or link to algorithmic results as presented in Monarch, please do so using our permalinks (a.k.a. "HTTP URI"). The permalink for each data page starts with the Monarch URL <a href="https://monarchinitiative.org/" target="_blank" rel="noopener noreferrer">https://monarchinitiative.org/</a> followed by the entity's prefixed accession (e.g. OMIM:154700); in this example, the permalink would be <a href="https://monarchinitiative.org/OMIM:154700" target="_blank" rel="noopener noreferrer">https://monarchinitiative.org/OMIM:154700</a>.</p>
<p>For example, the suggested citation for this record from OMIM at Monarch Initiative would be as follows:
Prefixed Identifier: OMIM:154700
http URI/permalink: <a href="https://monarchinitiative.org/OMIM:154700" target="_blank" rel="noopener noreferrer">https://monarchinitiative.org/OMIM:154700</a>
Suggested citation: Monarch Consortium. Accessed 2016-09-22. Marfan Syndrome. <a href="https://monarchinitiative.org/OMIM:154700" target="_blank" rel="noopener noreferrer">https://monarchinitiative.org/OMIM:154700</a></p>
<p>The complete set of transformed data for a given source database can be found at <a href="https://archive.monarchinitiative.org/latest/" target="_blank" rel="noopener noreferrer">https://archive.monarchinitiative.org/latest/</a></p>
<p>Please note that the data in Monarch is constantly expanding and improving. The data is versioned with each database release, and archived database releases are available upon request. The evolution of individual records in Monarch is not currently tracked. More documentation about our identifiers can be found in GitHub, at <a href="https://github.com/monarch-initiative/dipper/blob/master/README.md#identifiers" target="_blank" rel="noopener noreferrer">https://github.com/monarch-initiative/dipper/blob/master/README.md#identifiers</a></p>
</div>

</body></html></div></template>

<script>
  export default {
    name: "AboutMonarch",
    components: {}
  };
</script>